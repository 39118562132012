a:hover {
    background-color: #506d4a
}

.technology {
    background-color:#3f1754;
    box-shadow: inset 0 0 10px #200e20;
}

.project:hover {
    background-color: #2c1238;
}